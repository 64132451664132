input[type="radio"] {display: inline-block;}
input[type="radio"] + label:before {content: none;}
input[type="radio"] + label:after {content: none;}

input[type="checkbox"].custom {display: inline-block !important; vertical-align: middle;}
input[type="checkbox"].custom + label:before {content: none !important;}
input[type="checkbox"].custom + label:after {content: none !important;}

header + .owl-carousel .owl-prev, header + .owl-carousel .owl-next {background-color: #fff;}
header + .owl-carousel .owl-prev:hover, header + .owl-carousel .owl-next:hover {background-color: #0080bc;}
header + .owl-carousel .owl-item > div .teaser {background-color: #fff;}

header nav > ul > li:after {content: none;}
header nav > ul > li > ul {top: 60px;}

#logo img {margin-top: 33px;}
